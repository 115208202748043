import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import Sidebar from './containers/Sidebar/Sidebar';
// import Table from './containers/Tables/Table';

const App = () => (
  <Suspense fallback={'Loading...'}>
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
          {/* <BrowserRouter>
            <Switch>
              <Route path='/' element={<Sidebar />}>
                <Route index element={<Table />} />
                <Route path='blogs' element={<Blogs />} />
                <Route path='contact' element={<Contact />} />
                <Route path='*' element={<NoPage />} />
              </Route>
            </Switch>
          </BrowserRouter> */}
        </>
      </AppProvider>
    </Provider>
  </Suspense>
);
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
